import { Auth0Client } from "@auth0/auth0-spa-js";
import {
  AUTH0_APPLICATION_CONFIGS,
  generateAuth0ClientOptions
} from "../contexts/Auth0ApplicationConfigContext/constants";

enum IamProvider {
  Auth0 = "auth0"
}

export interface IamProviderAndTenantDomain {
  domain: string;
  provider: IamProvider;
  isSso?: boolean;
}

interface LoginWithRedirectOpts {
  iamConfig: IamProviderAndTenantDomain;
  returnTo: string;
  prefilledEmail?: string;
}

export const loginWithRedirect = async ({
  iamConfig: { domain, provider, isSso },
  returnTo,
  prefilledEmail
}: LoginWithRedirectOpts) => {
  if (provider === IamProvider.Auth0) {
    const foundConfig = AUTH0_APPLICATION_CONFIGS.find(
      (config) => config.domain === domain || (config.rawDomain && config.rawDomain === domain)
    );

    if (!foundConfig) {
      throw new Error(`Config not found for domain ${domain}`);
    }

    const client = new Auth0Client(generateAuth0ClientOptions(foundConfig));

    await client.loginWithRedirect({
      appState: {
        returnTo
      },
      authorizationParams: {
        // goes to config.internalOptions in login page
        ...(prefilledEmail && { login_hint: prefilledEmail }),

        // goes to config.extraParams in login page
        ...(isSso && { autoLoginWithSso: isSso })
      }
    });

    return;
  }

  throw new Error(`Unsupported login provider ${provider}`);
};
