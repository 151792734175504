import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import ErrorDialog from "../components/Dialogs/ErrorDialog";
import SuccessDialog from "../components/Dialogs/SuccessDialog";
import IntroContainer from "../components/IntroContainer/IntroContainer";
import { LoaderIcon } from "../components/Loader/Loader";
import LoginWrapper from "../components/LoginWrapper/LoginWrapper";
import Phone from "../components/Phone/Phone";
import { ClaimType } from "../components/ProcessAppointmentForm/helpers";
import { useFetchClinicianProfileById } from "../hooks/useGetClinicianProfileById";
import useGetVoiceCallToken from "../hooks/voiceCallHooks/useGetVoiceCallToken";
import { CustomFeature } from "../services/clinicianProfileService";
import { useAppState } from "../state";

const INTRO_TEXT = "Please enter the phone number to begin a secure phone call.";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    flex: "1"
  },
  innerContainer: {
    display: "flex",
    width: "888px",
    minHeight: "379px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px rgba(40, 42, 43, 0.3)",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      margin: "auto",
      maxWidth: "400px"
    }
  },
  loaderWrapper: {
    height: "100%"
  }
}));

export const VoiceCallPage = () => {
  const classes = useStyles();
  const { appointmentId, phoneNumber } = useParams<{ appointmentId?: string; phoneNumber?: string }>();
  const { error, setError, success, setSuccess, appointment } = useAppState();
  const { isLoading, token, client } = useGetVoiceCallToken(appointmentId);

  const { isLoading: isClinicianLoading, clinician } = useFetchClinicianProfileById(appointment?.clinicianId);

  const canProcessAppointment = useMemo<boolean>(() => {
    return (
      !!clinician?.customFeatures?.includes(CustomFeature.ProcessAppointment) &&
      !appointment?.isProcessed &&
      !appointment?.requestedChanges
    );
  }, [clinician?.customFeatures, appointment?.isProcessed, appointment?.requestedChanges]);

  const isSpecialAppointment = useMemo<boolean>(() => {
    return !appointment?.claimType || ![ClaimType.BULK_BILL, ClaimType.REBATE].includes(appointment.claimType);
  }, [appointment?.claimType]);

  return (
    <LoginWrapper>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <SuccessDialog
        dismiss={() => setSuccess(null)}
        successContent={success}
        allowProcessAppointment={
          !!appointmentId &&
          canProcessAppointment &&
          (isSpecialAppointment ? !!clinician?.customFeatures?.includes(CustomFeature.InvoiceListV2) : true)
        }
        appointmentId={appointmentId}
      />
      <IntroContainer customText={INTRO_TEXT}>
        {isLoading || isClinicianLoading ? (
          <div className={classes.loaderWrapper}>
            <LoaderIcon />
          </div>
        ) : (
          <Phone token={token} phoneNumber={client.phoneNumber || phoneNumber} firstName={client.firstName} />
        )}
      </IntroContainer>
    </LoginWrapper>
  );
};
